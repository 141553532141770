import * as React from "react";

export interface PasswordFieldProps {
  password: string;
  onChange: (password: string) => void;
}

const PasswordField = ({ password, onChange }: PasswordFieldProps) => {
  return (
    <div className="space-y-1">
      <label
        htmlFor="password"
        className="block text-sm font-medium text-gray-700"
      >
        Password
      </label>
      <input
        id="password"
        name="password"
        type="password"
        autoComplete="current-password"
        value={password}
        onChange={(e) => onChange(e.target.value)}
        required
        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
      />
    </div>
  );
};

export default PasswordField;
