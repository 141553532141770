import { Link } from "gatsby";
import * as React from "react";
import EmailField from "../fields/EmailField";
import PasswordField from "../fields/PasswordField";

export interface SignInFormProps {
  email: string;
  password: string;
  disabledEmail?: boolean;
  trailingEmail?: React.ReactNode;
  onEmailChange: (email: string) => void;
  onPasswordChange: (password: string) => void;
  onButtonClick: (isValid: boolean) => void;
}

const SignInForm = (props: SignInFormProps) => {
  const { disabledEmail = false } = props;
  const [rememberMe, setRememberMe] = React.useState(true);

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const isFormValid = event.currentTarget.checkValidity();
    props.onButtonClick(isFormValid);
  };

  return (
    <form onSubmit={submitForm} className="space-y-6">
      <EmailField
        email={props.email}
        disabled={disabledEmail}
        trailing={props.trailingEmail}
        onChange={props.onEmailChange}
      />
      <PasswordField
        password={props.password}
        onChange={props.onPasswordChange}
      />
      <div className="flex items-center justify-between">
        <div></div>
        <div className="flex hidden items-center">
          <input
            id="remember-me"
            name="remember-me"
            type="checkbox"
            checked={rememberMe}
            onChange={() => setRememberMe(!rememberMe)}
            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
          />
          <label
            htmlFor="remember-me"
            className="ml-2 block text-sm text-gray-900"
          >
            Remember me
          </label>
        </div>
        <div className="text-sm">
          <Link
            to="/auth/forgot-password"
            className="font-medium text-indigo-600 hover:text-indigo-500"
          >
            Forgot your password?
          </Link>
        </div>
      </div>
      <button
        type="submit"
        className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        Sign in
      </button>
    </form>
  );
};

export default SignInForm;
